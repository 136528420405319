<template lang="pug">
settings-shell.edit-entry-screen
  template(#head)
    h1.title Category Settings
  template(#actions)
    sort-changer
    b-button.action.is-primary(@click="showNewAssetCategory = true") Add Asset
    b-button.action(@click="showNewLiabilityCategory = true") Add Liability
  b-loading(:is-full-page="true" :active="initializing" :can-cancel="false")
  .category-editor(v-if="!initializing")
    draggable.editor.assets(v-if="assets.length" v-model="assets" group="assets" handle=".handle" :scroll-speed="20" :scroll-sensitivity="50" :disabled="disableDragDrop")
      category-editor(v-for="(category, index) in assets" :index="index" :key="category.id" tag="div" :category="category" ref='assets' @dirty="calcDirtyState" :show-handle="!disableDragDrop")
    h2.subtitle(v-if="assets.length && liabilities.length") Liabilities
    draggable.editor.liabilities(v-if="liabilities.length" v-model="liabilities" group="liabilities" handle=".handle" :scroll-speed="20" :scroll-sensitivity="50" :disabled="disableDragDrop")
      category-editor(v-for="(category, index) in liabilities" :index="index" :key="category.id" tag="div" :category="category" ref='liabilities' @dirty="calcDirtyState" :show-handle="!disableDragDrop")
  b-modal(:active.sync="showNewAssetCategory" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    new-asset-dialog
  b-modal(:active.sync="showNewLiabilityCategory" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    new-liability-dialog
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

import CategoryEditor from '@/components/CategoryEditor'
import NewAssetDialog from '@/components/NewAssetDialog'
import NewLiabilityDialog from '@/components/NewLiabilityDialog'
import SettingsShell from '@/components/SettingsShell'
import SortChanger from '@/components/SortChanger'

export default {
  components: {
    CategoryEditor,
    NewAssetDialog,
    NewLiabilityDialog,
    SettingsShell,
    Draggable,
    SortChanger
  },
  data() {
    return {
      errorMessage: null,
      showNewLiabilityCategory: false,
      showNewAssetCategory: false,
      anyDirty: false
    }
  },
  computed: {
    ...mapState('categories', ['loadingCategories']),
    ...mapGetters('categories', ['orderedCategories']),
    ...mapState('entries', ['loadingEntries']),
    ...mapGetters('authentication', ['categoryOrder']),
    initializing() {
      return this.loadingCategories || this.loadingEntries
    },
    assets: {
      get() {
        const { orderedCategories } = this
        if (!orderedCategories) return []
        return Object.values(orderedCategories).filter(({ isAsset }) => isAsset)
      },
      set(assets) {
        this.saveOrder(assets)
      }
    },
    liabilities: {
      get() {
        const { orderedCategories } = this
        if (!orderedCategories) return []
        return Object.values(orderedCategories).filter(
          ({ isAsset }) => !isAsset
        )
      },
      set(liabilities) {
        this.saveOrder(liabilities)
      }
    },
    disableDragDrop() {
      const { anyDirty, categoryOrder } = this
      return anyDirty || categoryOrder !== 'user'
    }
  },
  methods: {
    ...mapActions('categories', ['saveOrder']),
    calcDirtyState() {
      let anyDirty = false
      if (this.$refs.assets) {
        if (this.$refs.assets.find(({ isDirty }) => isDirty)) {
          anyDirty = true
        }
      }
      if (this.$refs.liabilities) {
        if (this.$refs.liabilities.find(({ isDirty }) => isDirty)) {
          anyDirty = true
        }
      }
      this.anyDirty = anyDirty
    }
  },
  metaInfo: {
    title: 'Category Settings'
  }
}
</script>
<style lang="sass" scoped>
.editor
  padding-bottom: 1rem
.category-editor
  margin-bottom: 0.75rem
h2.subtitle
  margin-top: 2rem
  font-size: 1.5rem
.sortable-ghost
  opacity: 0
.sort-menu
  order: 3
.action
  order: 2
@media screen and (min-width: 1024px)
  .sort-menu
    order: 1
@media screen and (min-width: 640px)
  .editor
    display: grid
    grid-gap: 1rem
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
</style>
